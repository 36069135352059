import { useState, useEffect } from "react";
import { FloatingWhatsApp } from 'react-floating-whatsapp'

import {
  Navbar,
  MobileNav,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Typography,
  IconButton,
} from "@material-tailwind/react";

import {
  BiUserCircle,
  BiChalkboard,
  BiCopyAlt,
  BiChevronDown,
  BiSidebar,
  BiCoinStack,
} from "react-icons/bi";

import MenuTopBar from "../sidebar/menu";
import { userService } from "../../../api/user";

import Logo from "../../../assets/logo.svg";
import { Link } from "react-router-dom";
import { adminService } from "../../../api/admin";
import useDashboardStore from "../../../zustandStore/dashboardStore";

export default function NavBar() {

  const [openNav, setOpenNav] = useState(false);
  const [user, setUser] = useState(null);
  const dashboardStore = useDashboardStore((state) => { return state; });

  const token = localStorage.getItem("token");

  const getUser = () => {
    userService
      .getUser({ token })
      .then((response) => {
        setUser(response?.data?.payload[0]);
      })
      .catch((error) => {});
  };
  
  const getCredits = () => {
    
    if (user?.role === "tutor") { return; }

    adminService
      .getCredits({ token: localStorage.getItem('token') || '' })
      .then((response) => {
        if (response?.data?.payload) {
          dashboardStore.dashboard.credits = response?.data?.payload.data[0].credit || 0;
          useDashboardStore.setState({ ...dashboardStore });
        }
      })
      .catch((error) => {
      });
  };

  useEffect(()=>{
    getCredits();
    // eslint-disable-next-line
  }, [user, dashboardStore.dashboard.credits])

  useEffect(() => {
    getUser();
    // eslint-disable-next-line
  }, [setUser]);

  useEffect(() => {
    window.addEventListener( "resize", () => window.innerWidth >= 960 && setOpenNav(false) );
  }, []);

  const getMenuAdmin = () => {
    if (user?.role === "admin") {
      return (
        <>        
        <Menu>
          <MenuHandler>
            <Typography
              as="li"
              // variant="small"
              color="blue-gray"
              className="p-1 font-medium cursor-pointer flex flex-wrap items-center"
            >
              <span className="flex items-center">
                <BiSidebar size={24} /> <span className="ml-1">Diretti</span>
              </span>
              <BiChevronDown />
            </Typography>
          </MenuHandler>
          <MenuList>
              <Link to={"/admin/users/student"}><MenuItem>Studenti</MenuItem></Link>
              <Link to={"/admin/users/tutor"}><MenuItem>Tutor</MenuItem></Link>
              <Link to={"/admin/user-log"}><MenuItem>Accessi</MenuItem></Link> 
              <hr/>
              <Link to={"/admin/course-onair"}><MenuItem>Avanzamento</MenuItem></Link> 
              <Link to={"/admin/certificates"}><MenuItem>Attestati</MenuItem></Link>
              <Link to={"/admin/examspassed"}><MenuItem>Esami Superati</MenuItem></Link>
              <Link to={"/admin/weeklycertificatesreport"}><MenuItem>Certificati giornalieri</MenuItem></Link>
              <hr/>
              <Link to={"/admin/courses"}><MenuItem>Corsi</MenuItem></Link>
              <Link to={"/admin/coupons"}><MenuItem>Coupons</MenuItem></Link>
              <hr/>
              <Link to={"/admin/domains"}><MenuItem>Siti</MenuItem></Link>      
          </MenuList>
        </Menu>
              
        <Menu>
          <MenuHandler>
            <Typography
              as="li"
              // variant="small"
              color="blue-gray"
              className="p-1 font-medium cursor-pointer flex flex-wrap items-center"
            >
              <span className="flex items-center">
                <BiSidebar size={24} /> <span className="ml-1">Tutor</span>
              </span>
              <BiChevronDown />
            </Typography>
          </MenuHandler>
          <MenuList>
              <Link to={"/admin/users/student-tutor"}><MenuItem>Studenti</MenuItem></Link>
              <Link to={"/admin/course-onair-tutor"}><MenuItem>Avanzamento</MenuItem></Link>
              <Link to={"/admin/certificates-tutor"}><MenuItem>Attestati</MenuItem></Link>
              <Link to={"/admin/examspassed-tutor"}><MenuItem>Esami Superati</MenuItem></Link>
          </MenuList>
        </Menu>
        </>
      );
    }
  };

  const getMenuTutor = () => {
    if (user?.role === "tutor") {
      return (
        <Menu>
          <MenuHandler>
            <Typography
              as="li"
              // variant="small"
              color="blue-gray"
              className="p-1 font-medium cursor-pointer flex flex-wrap items-center"
            >
              <span className="flex items-center">
                <BiSidebar size={24} /> <span className="ml-1">Tutor</span>
              </span>
              <BiChevronDown />
            </Typography>
          </MenuHandler>
          <MenuList>
              <Link to={"/admin/priceList"}><MenuItem>Acquista</MenuItem></Link>
              <MenuItem><hr/></MenuItem>            
              <Link to={"/admin/users/student"}><MenuItem>Studenti</MenuItem></Link>
              <Link to={"/admin/course-onair"}><MenuItem>Avanzamento</MenuItem></Link>  
              <Link to={"/admin/certificates"}><MenuItem>Attestati</MenuItem></Link>           
          </MenuList>
        </Menu>
      );
    }
  };

  //--- ---

  const navList = (
    <ul className="mb-4 mt-2 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6">
      {
      (user?.role === "student") && 
      <>
      <Typography as="li" color="blue-gray" className="p-1 font-medium">
        <Link to={"/courses"} className="flex items-center">
          <BiChalkboard size={24} /> <span className="ml-1">Corsi</span>
        </Link>
      </Typography>
      {(parseInt(user?.idParent) === 0) && <Typography as="li" color="blue-gray" className="p-1 font-medium">
        <Link to={"/certificates"} className="flex items-center">
          <BiCopyAlt size={24} /> <span className="ml-1">Attestati</span>
        </Link>
      </Typography>}
      </>
      }
      <Typography
        as="li"
        // variant="small"
        color="blue-gray"
        className="p-1 font-medium"
      >
        <Link to={"/account"} className="flex items-center">
          <BiUserCircle size={24} />{" "}
          <span className="ml-1">Il tuo account</span>
        </Link>
      </Typography>

      {(user?.role === "tutor") && <Typography
        as="li"
        // variant="small"
        color="blue-gray"
        className="p-1 font-medium"
      >
        <Link to={"/admin/priceList"} className="flex items-center">
          <BiCoinStack size={24} />{" "}
          <span className="ml-1">Crediti ({dashboardStore.dashboard.credits})</span>
        </Link>
      </Typography>}

      {getMenuAdmin()}
      {getMenuTutor()}
    </ul>
  );

  return (
    <>
      <Navbar className="mx-auto container py-2 px-4 lg:px-8 lg:py-4">
        <div className="container mx-auto flex items-center justify-between text-blue-gray-900">
          <div className="mr-4 cursor-pointer py-1.5">
            <Link to={"/"}>
              <img src={Logo} alt="logo" width="50" />
            </Link>
          </div>
          <div className="hidden lg:block">{navList}</div>
          <MenuTopBar />
          <IconButton
            variant="text"
            className="ml-auto h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
            ripple={false}
            onClick={() => setOpenNav(!openNav)}
          >
            {openNav ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                className="h-6 w-6"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            )}
          </IconButton>
        </div>
        <MobileNav open={openNav}>{navList}</MobileNav>
      </Navbar>

      {
          (user?.role === "tutor") ? 
            <FloatingWhatsApp
              phoneNumber="3914019277"
              accountName="haccpForma"
              statusMessage=""
              chatMessage="Ciao, come posso aiutarti?"
              placeholder="Scrivi un messaggio ..."
              avatar="/images/logo.svg"
            />
        : ''
        }
    </>
  );
}
