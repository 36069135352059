import { useState, useEffect } from "react";
import { saveAs } from "file-saver";
//import QRCode from "react-qr-code";

import {
  Card,
  //CardHeader,
  CardBody,
  CardFooter,
  Typography,
  //Tooltip,
  Button,
} from "@material-tailwind/react";

import { formatDate } from "../../utils/date";

import { pdf, /*Document, Page*/ } from "@react-pdf/renderer";

import { HiDownload } from "react-icons/hi";

import Layout from "../../components/layout";

import PdfCreate from "../../components/pdfCreate";
import QRcode from "qrcode.react";

import { userService } from "../../api/user";

export default function CertificatesView() {
  let token = localStorage.getItem("token");
  const [certificates, setCertificates] = useState(null);

  const getCertificates = () => {
    userService
      .getUserAllCertification({ token })
      .then((response) => {
        if (response?.data?.payload) {
          setCertificates(response?.data?.payload);
        }
      })
      .catch((error) => {
        setCertificates(false);
      });
  };

  useEffect(() => {
    getCertificates();
    // eslint-disable-next-line
  }, [setCertificates]);

  const generatePDFDocument = async (item) => {
    let base64Image = document.getElementById("qrcode").toDataURL();
    if (base64Image) {
      const blob = await pdf(
        <PdfCreate qrcode={base64Image} data={item} />
      ).toBlob();
      saveAs(
        blob,
        "certificato-haccp-" + item?.item?.name + "-" + item?.item?.surname
      );
    }
  };

  if (certificates === null) {
    return (
      <div className="w-full h-screen flex flex-row justify-center items-center">
        <span className="loader"></span>
      </div>
    );
  }

  /* no certificates found */
  if (!certificates) {
    return (
      <Layout>
        <div className="container mx-auto">
          <div className="mt-4 bg-white p-4">
            <p>Nessun certificato presente.</p>
            <div className="mt-4">
              <a href="/courses">
                <Button>Vai ai corsi</Button>
              </a>
            </div>
          </div>
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <div className="container mx-auto">
        <p className="text-2xl font-bold">I tuoi attestati</p>
        {/* certificates list */}
        <div className="mt-4 bg-white p-4">
          <div className="my-4 w-full lg:grid grid-cols-2 gap-4">
            {certificates &&
              certificates.map((item, index) => (
                <Card
                  className="w-full lg:w-96 cursor-pointer mb-4"
                  onClick={() => generatePDFDocument({ item })}
                  key={index}
                >
                  <CardBody className="text-center">
                    <Typography variant="h6" color="blue-gray" className="mb-2">
                      {item?.courseTitle}
                    </Typography>
                    <Typography
                      variant="h6"
                      color="blue-gray"
                      className="mb-2 uppercase"
                    >
                      {item?.name} {item?.surname}
                    </Typography>
                    {!item?.releasedAt ? (
                      <Typography
                        color="blue"
                        className="font-medium"
                        textGradient
                      >
                        Corso Iniziato. <br />
                        Segui tutte le lezioni del corso, supera il test finale
                        e procedi con il pagamento per ottenere l'attestato.
                      </Typography>
                    ) : (
                      <>
                        <Typography variant="h6" color="blue-gray">
                          Rilasciato il {formatDate(item?.releasedAt)}
                        </Typography>
                        <Typography variant="h6" color="blue-gray">
                          Valido fino al {formatDate(item?.expiredAt)}
                        </Typography>
                      </>
                    )}
                    {item?.releasedAt ? (
                      <div className="flex flex-wrap justify-center mt-4 mb-4">
                        {/* <QRcode
                          value={
                            "https://app.labmar.it/backend/v1/userCertification/verifyCheck/?verify=" +
                            item?.code +
                            ""
                          }
                          id="qrcode"
                        /> */}

                        <QRcode
                          value={
                            "https://app.labmar.it/verifyQrCode?verify=" +
                            item?.code +
                            ""
                          }
                          id="qrcode"
                        />
                      </div>
                    ) : null}
                  </CardBody>
                  {item?.releasedAt ? (
                    <CardFooter className="flex justify-center gap-7 pt-2">
                      <div>
                        <p>Scarica</p>
                        <HiDownload size={48} />
                      </div>
                    </CardFooter>
                  ) : null}
                </Card>
              ))}
          </div>
        </div>
      </div>
    </Layout>
  );
}
