import { Button } from "@material-tailwind/react";
import { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import { useForm } from "react-hook-form";
import { BiXCircle } from "react-icons/bi";
import { userService } from "../../api/user";
import styles from "./style.module.scss";
import { adminService } from "../../api/admin";
import { courseService } from "../../api/course";

function UpoloadFile(props) {
  //-- state
  const [isResponse, setIsResponse] = useState("");
  //const [accept, setAccept] = useState(false);

  // -- files
  const [multipleFiles, setMultipleFiles] = useState([]);
  const [typeFile, setTypeFile] = useState([]);
  const [isTooBig, setIsTooBig] = useState(false);
  const [uploaded, setUpladed] = useState(false);

  const [priceDomain, setPriceDomain] = useState(0);
  const [/*priceDiscountDomain*/, setPriceDiscountDomain] = useState(0);
  const [appConfig, setAppConfig] = useState();

  const { course, userData, registrationCode, setIsFileUploaded } = props || "";

  const token = localStorage.getItem("token");

  let navigate = useNavigate();

  /** */
  const getAppConfig = () => {
    adminService.getAppConfig({ token }).then((response) => {
      setAppConfig(response?.data?.payload);
    }).catch((error) => {
    })
  }//getAppConfig

  const getPriceByUserDomain = () => {
    const params = {
      token,
      courseCode: course.code
    };

    courseService.getPriceByDomain({ params })
      .then((response) => {
        //console.log(response?.data?.payload)
        setPriceDomain((response?.data?.payload?.price > 0) ? response?.data?.payload?.price : course.price);
        setPriceDiscountDomain((response?.data?.payload?.priceDiscount > 0) ? response?.data?.payload?.priceDiscount : course.priceDiscount);
      })
      .catch((error) => { });
  }//getPriceByUserDomain

  useEffect(() => {
    getAppConfig();
    getPriceByUserDomain();
    // eslint-disable-next-line
  }, [])

  /*function handleAccept() {
    setAccept(!accept);
  }*/

  const {
    register,
    handleSubmit,
    formState,
    formState: { errors },
  } = useForm();

  //--- convert file in base64
  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  //--- onSubmit of form
  const onSubmit = async (data) => {

    data.file = [];
    data.file.push(multipleFiles);

    let params = {
      token: token,
      registrationCode: registrationCode,
      paymentAmount:
        userData?.userBonus && userData?.userBonus !== null
          ? 18
          : (((parseFloat(priceDomain) * parseFloat(appConfig?.data[0]?.iva || 0)) / 100) + parseFloat(priceDomain)).toFixed(2),
      usedBonus: userData?.userBonus,
      ext: typeFile,
      base64: data?.file[0],
    };

    userService
      .uploadPayment({ params })
      .then((response) => {
        setUpladed(true);
        setIsResponse("success");
        setIsFileUploaded(true);
      })
      .catch((error) => {
        setIsResponse("error");
      });
  };

  //--- on input file change
  async function handleUpload(e) {
    e.preventDefault();
    //--- upload state isTooBig for remove last selected file
    setIsTooBig(false);
    const files = e.target.files[0];

    //--- verify if previous file name is unique
    let currentFile = multipleFiles.findIndex(
      (x) => x.name === e.target.files[0].name
    );

    if (currentFile > -1) {
      document.getElementById("file").value = "";
      return false;
    }

    const fileSize = files.size / 1000;

    if (fileSize > 20000) {
      setIsTooBig(true);
    } else {
      const base64File = await toBase64(files);
      const obj = {
        name: files.name,
        url: base64File,
      };

      setMultipleFiles([...multipleFiles, obj]);
      setTypeFile(files.type);
      const formData = new FormData();
      formData.append("file", multipleFiles);
    }
    document.getElementById("file").value = "";
  }

  //--- on remove items uploaded
  const removeAttach = (item) => {
    const fileIndex = multipleFiles.findIndex((x) => x.name === item.name);
    if (fileIndex > -1) {
      delete multipleFiles[fileIndex];
      // multipleFiles = multipleFiles.filter(Boolean);
      setMultipleFiles(multipleFiles.filter(Boolean));
    }
  };

  //--- show form after error message
  const retryToSend = () => {
    setIsResponse("");
  };

  //--- manage limit file uploaded and return render
  const getUploadInput = () => {
    if (multipleFiles.length < 1) {
      return (
        <div className="mt-1 flex flex-col justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6 bg-white">
          <label htmlFor="file" className={styles.label}>
            {isTooBig ? (
              <span className="text-red">Puoi caricare al massimo 20MB</span>
            ) : null}
          </label>
          <input
            id="file"
            type="file"
            name="file1"
            multiple
            accept="image/*, .pdf, .doc, .docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            className={styles.inputFile}
            {...register("file")}
            onChange={handleUpload}
          />
          {errors.file && <p>Seleziona un file</p>}
          <p className={styles.fileLabel}>formato: pdf ed immagini</p>
        </div>
      );
    } else {
      return <div>Documento caricato</div>;
    }
  };

  return (
    <div className="panel" id="richiesta-iscrizioni">
      {isResponse === "" ? (
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="container mx-auto p-4 md:p-0"
        >
          {getUploadInput()}

          <div className={styles.listAttach}>
            <>
              <ul>
                {multipleFiles.map((item, index) => {
                  return (
                    <li key={index}>
                      <div className="flex flex-wrap align-items-center">
                        <span
                          className={styles.deleteIcon}
                          onClick={() => removeAttach(item)}
                        >
                          <BiXCircle />
                        </span>
                        {item.name}
                      </div>
                    </li>
                  );
                })}
              </ul>
            </>
          </div>
          {multipleFiles.length > 0 && !uploaded && (
            <div className="flex justify-content-end mt-2">
              <div>
                <Button type="submit">Carica</Button>
                <p className="mt-2">
                  Clicca su carica per caricare il documento
                </p>
              </div>
            </div>
          )}
          {uploaded && (
            <p className="text-green-800">Documento caricato, grazie</p>
          )}
        </form>
      ) : (
        ""
      )}
      {isResponse === "success" ? (
        <div className="w-full text-center bg-green-600 p-8 rounded-xl">
          <p className="text-lg text-white">
            Grazie per averci inviato la ricevuta, attendi la verifica
            dell'attestato per ottenerlo.
          </p>
          <div className="mt-4 flex justify-center">
            <Button onClick={() => navigate("/certificates")}>
              Vai ai tuoi attestati
            </Button>
          </div>
        </div>
      ) : null}
      {isResponse === "error" ? (
        <>
          <div className="w-full text-center bg-red-600 p-8 rounded-xl">
            <p className="text-lg text-white">Si è verificato un errore.</p>
          </div>
          <div onClick={() => retryToSend()}>
            <Button>Riprova</Button>
          </div>
        </>
      ) : null}
    </div>
  );
}

export default UpoloadFile;
