import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { formatDate, generaCodice } from "../../utils/date";
import { Alert, Button } from "@material-tailwind/react";

import Layout from "../../components/layout";
import PaymentForm from "../../components/paymentForm";
import PayPalCheckout from "../../components/paypalCheckout";

import { userService } from "../../api/user";
import BankPayment from "../../components/bankPayment";

export default function PaymentView() {
  const location = useLocation();
  const { course } = location?.state || null;

  const [/*showPayment*/, setShowPayment] = useState(false);
  const [userData, setUserData] = useState();
  const [registrationCode, setRegistrationCode] = useState("");
  const [viewFormUserData, setViewFormUserData] = useState(false);
  const [/*certificates*/, setCertificates] = useState(null);
  const [/*isFileUploaded*/, setIsFileUploaded] = useState(false);

  //-- token
  const token = localStorage.getItem("token");

  //-- get userData if exist
  const getUserData = () => {
    //-- params
    const params = {
      token,
    };

    userService
      .getPersonalData({ params })
      .then((response) => {
        //-- set state
        setUserData(response?.data?.payload[0]);
        setShowPayment(true);
      })
      .catch((error) => {});
  };

  const getCertificates = () => {
    userService
      .getUserAllCertification({ token })
      .then((response) => {
        if (response?.data?.payload) {
          setCertificates(response?.data?.payload);
          setRegistrationCode(response?.data?.payload[0]?.registrationCode);
        }
      })
      .catch((error) => {
        setCertificates(false);
      });
  };

  useEffect(() => {
    getCertificates();

    // eslint-disable-next-line
  }, [setCertificates]);

  //-- course method hook
  useEffect(() => {
    getUserData();

    // eslint-disable-next-line
  }, [setUserData]);

  const editUserData = () => {
    setViewFormUserData(true);
  };

  const closeCourse = () => {
    // ok response
    const token = localStorage.getItem("token");
    const params = {
      token: token,
      registrationCode: registrationCode,
      paymentType: "tutor",
      paymentTransactionID: generaCodice('tr'),
      paymentAmount: 0,
      usedBonus: null,
    };
    userService
      .setPayed({ params })
      .then((response) => {
        fetch('https://app.labmar.it/backend/v1/userCertification/setUserReleasedAtCertification?registrationCode='+registrationCode)
        .then(response => {
          if (!response.ok) {
            throw new Error('Errore nella richiesta!');
          }
          return response.json();
        })
        .then(data => {
        })
        .catch(error => {
          console.error('Si è verificato un errore:', error);
        });
      })
      .catch((error) => {
      });
  };

  if(userData?.idParent > 0){
    closeCourse();
  }

  //--- ---

  return (
    <>
    {(userData?.idParent > 0) && <Layout>
      <div className="container mx-auto">
        <div className="mt-4">
          
        <Alert color="green" className="text-center font-bold">
              Complimenti. Hai superato il Test FInale. Contatta il tuo Tutor per il rilascio dell'attestato.
            </Alert>

        </div>
      </div>
    </Layout>}


    {(userData?.idParent === 0) &&<Layout>
      <div className="container mx-auto">
        <div className="mt-4">
          <p className="text-2xl font-bold">Certificato {course?.title}</p>
        </div>
        {!userData ||
          (viewFormUserData && (
            <div className="mt-8 mb-8 bg-white p-4">
              <PaymentForm
                setShowPayment={setShowPayment}
                userData={userData}
                setViewFormUserData={setViewFormUserData}
                getUserData={getUserData}
              />
            </div>
          ))}
        {userData && !viewFormUserData && (
          <div className="mt-10 rounded-3xl bg-white p-4">
            <p className="text-xl fond-bold">I tuoi dati di fatturazione</p>
            {
              // userData?.taxID || userData?.fiscalCode && (
              <div>
                {userData?.fiscalCode && userData?.fiscalCode !== "" && (
                  <div className="mt-4">
                    <p className="font-bold">
                      {userData?.name} {userData?.surname}
                    </p>
                    <p>
                      Nato il{" "}
                      <span className="font-bold">
                        {userData?.dateOfBirth
                          ? formatDate(userData?.dateOfBirth)
                          : ""}
                      </span>{" "}
                      a {userData?.birthCity} ({userData?.birthProvince})
                    </p>
                    <p>
                      CF:{" "}
                      <span className="font-bold">{userData?.fiscalCode}</span>
                    </p>
                    <p>
                      Residente in{" "}
                      <span className="font-bold">{userData?.address}</span> n.{" "}
                      <span className="font-bold">{userData?.houseNumber}</span>
                      ,{" "}
                      <span className="font-bold">
                        {userData?.zipCode} {userData?.residenceCity} (
                        {userData?.residenceProvince})
                      </span>
                    </p>
                  </div>
                )}
                {userData?.taxID && userData?.taxID !== "" && (
                  <div className="mt-4">
                    <p className="font-bold">{userData?.companyName}</p>
                    <p>
                      P.iva <span className="font-bold">{userData?.taxID}</span>
                    </p>
                    <p>
                      <span className="font-bold">
                        {userData?.address} n, {userData?.houseNumber},{" "}
                        {userData?.zipCode} {userData?.residenceCity} (
                        {userData?.residenceProvince}){" "}
                      </span>
                    </p>
                    <p>
                      PEC <span className="font-bold">{userData?.pec}</span>
                    </p>
                    <p>
                      Codice unico{" "}
                      <span className="font-bold">
                        {userData?.uniqueCodeBilling}
                      </span>
                    </p>
                  </div>
                )}
              </div>
              // )
            }
            {userData && (
              <div className="mt-4">
                <Button onClick={() => editUserData()}>
                  {userData?.taxID || userData?.fiscalCode
                    ? "Modifica dati"
                    : "Inserisci i tuoi dati"}
                </Button>
              </div>
            )}

            {(userData?.idParent > 0) && (
              <>
                <div className="mt-8">
                <div className="bg-teal-100 border-t-4 border-teal-500 rounded-b text-teal-900 px-4 py-3 shadow-md" role="alert">
                  <div className="flex">
                    <div className="py-1"><svg className="fill-current h-6 w-6 text-teal-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg></div>
                    <div>
                      <p className="font-bold">Dopo aver verificato la correttezza dei tuoi dati</p>
                      <p className="text-base">Puoi procedere con la chiusura del corso e contattare il tuo Tutor richiedendo il certifiato HACCP</p>
                      <Button className="text-lg mt-6 px-6 py-5" onClick={() => closeCourse()}>Ho Capito, ho verificato e chiudo il corso.</Button>
                    </div>
                  </div>
                </div>
                </div>
              </>
            )}
          </div>
        )}

        {(userData?.residenceCity && userData?.residenceCity !== "" && userData?.idParent === 0) && (
          <>
            {/* stripe */}
            {/* <div className="mt-4 bg-white p-4">
              <div className="flex flex-wrap items-center mb-4">
                <FaCcStripe size={36} />
                <p className="text-xl ml-2 font-bold">Stripe</p>
              </div>
              <hr />
              <div className="mt-4"></div>
            </div> */}
            {/* end stripe */}

            {/* PAYPAL */}
            {registrationCode && userData?.taxID === "" && (
              <PayPalCheckout
                userData={userData}
                course={course}
                registrationCode={registrationCode}
              />
            )}
            
            <BankPayment
              userData={userData}
              course={course}
              registrationCode={registrationCode}
              setIsFileUploaded={setIsFileUploaded}
            />

            {/* {isFileUploaded && (
              <div className="mt-8 flex justify-end">
                <Button onClick={() => navigate("/certificates")}>Invia</Button>
              </div>
            )} */}

            {/* <div className="mt-8 bg-yellow-700 p-4">
              <p>
                Stiamo aggiornando i sistemi di pagamento tramite{" "}
                <strong>carta di credito, paypal e stripe</strong>. I pagamenti
                digitali torneranno presto disponibili, ci scusiamo per il
                disagio.
              </p>
            </div> */}
          </>
        )}
      </div>
    </Layout>
    }
    </>
  );
}
